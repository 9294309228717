
import './App.css';
import './html5up-alpha/assets/css/main.css';
import Logo from "./Logo";
import LogoCursinho from "./LogoCursinho";

function App() {
  return (
    <div className="App">
          <div id="page-wrapper">
              <header id="header" className="alt">
                  <h1>Cursinho Carolina Maria de Jesus - DAGV/UFTM - Projeto de extensão - N.º 19/2023 - Universidade Federal do Triângulo Mineiro - Uberaba-MG</h1>
                  {/*<nav id="nav">*/}
                  {/*    <ul>*/}
                  {/*        <li><a href="index.html">Home</a></li>*/}
                  {/*        <li>*/}
                  {/*            <a href="#" className="icon solid fa-angle-down">Layouts</a>*/}
                  {/*            <ul>*/}
                  {/*                <li><a href="generic.html">Generic</a></li>*/}
                  {/*                <li><a href="contact.html">Contact</a></li>*/}
                  {/*                <li><a href="elements.html">Elements</a></li>*/}
                  {/*                <li>*/}
                  {/*                    <a href="#">Submenu</a>*/}
                  {/*                    <ul>*/}
                  {/*                        <li><a href="#">Option One</a></li>*/}
                  {/*                        <li><a href="#">Option Two</a></li>*/}
                  {/*                        <li><a href="#">Option Three</a></li>*/}
                  {/*                        <li><a href="#">Option Four</a></li>*/}
                  {/*                    </ul>*/}
                  {/*                </li>*/}
                  {/*            </ul>*/}
                  {/*        </li>*/}
                  {/*        <li><a href="#" className="button">Sign Up</a></li>*/}
                  {/*    </ul>*/}
                  {/*</nav>*/}

              </header>


              <section id="banner">
                  <LogoCursinho/>
                  <h2>Bem-vindos ao Cursinho Carol da UFTM!</h2>
                  <p>Projeto de extensão do Diretório Acadêmico Gaspar Viana - Medicina/UFTM</p>

              </section>



              <section id="main" className="container">


                  <section className="box special features">
                      <div className="features-row">
                          <section>
                              <span className="icon solid major fa-bolt accent2"></span>
                              <h3>Alunos Aprovados</h3>
                              <ul className="actions">
                                  <li><a href="https://docs.google.com/document/d/1uX4zUu-8h6-Iy29CklibVABwfz-DjBFcAP3Sw5hu2Vw/edit" className="button special fit icon solid fa-download" target="_blank">VER LISTA</a></li>
                              </ul>
                              <h3>Inscrição para aluno</h3>
                              <p>AINDA TEMOS VAGA!</p>
                              <h4><b><a href="https://drive.google.com/file/d/1D5E0gyJ9xJDqTPc6V3Eg0I0ZcjTyEO2E/view"  target="_blank">EDITAL</a></b></h4>
                              <p><strong>Inscrições até 3 de março de 2023</strong></p>


                              <ul className="actions">
                                  <li><a href="https://forms.gle/AE3jNMveFSV5tCbHA" className="button special fit">Inscrição para aluno</a></li>
                              </ul>
                          </section>
                          <section>
                              <span className="icon solid major fa-puzzle-piece accent3"></span>
                              <h3>Voluntários aprovados</h3>
                              {/*<p>Faça parte do nosso projeto como professor ou monitor!</p>*/}
                              {/*<h4><b><a href="https://drive.google.com/file/d/1RCX51wZG92hdnkXraXFBbWwZVjWunTLg/view"  target="_blank">EDITAL</a></b></h4>*/}
                              {/*<p><strong>Inscrições até 11 de fevereiro de 2023</strong></p>*/}


                              <ul className="actions">
                                  <li><a href="https://drive.google.com/file/d/1n1LZ0mXPu7hc0BOlMWrTS3DkPjJqEbWL/view?usp=share_link" className="button special fit icon solid fa-download" target="_blank">VER LISTA</a></li>
                              </ul>



                          </section>
                      </div>
                      {/*<div className="features-row">*/}
                      {/*    <section>*/}
                      {/*        <span className="icon solid major fa-cloud accent4"></span>*/}
                      {/*        <h3>Sed feugiat</h3>*/}
                      {/*        <p>Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut*/}
                      {/*            enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros.</p>*/}
                      {/*    </section>*/}
                      {/*    <section>*/}
                      {/*        <span className="icon solid major fa-lock accent5"></span>*/}
                      {/*        <h3>Enim phasellus</h3>*/}
                      {/*        <p>Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut*/}
                      {/*            enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros.</p>*/}
                      {/*    </section>*/}
                      {/*</div>*/}
                  </section>

                  <section className="box special">

                      <header className="major">

                          <h2> INÍCIO DAS AULAS: 6 DE MARÇO! </h2>

                          <h3> Local: Anfiteatro da FUNEPU <br/> Rua Getúlio Guaritá, 331</h3>

                          <h3> Horário: 19h</h3>


                      </header>
                  </section>

                  <section className="box special">

                      <header className="major">

                          <h2> "Ah, comigo o mundo vai modificar-se.<br/> Não gosto do mundo como ele é."<br/></h2>(Carolina Maria de Jesus)

                          <p>O curso pré-vestibular Carolina Maria de Jesus nasceu da vontade de um grupo de alunos da também Gestão Carolina Maria de Jesus do Diretório Acadêmico Gaspar Vianna, da medicina UFTM, em criar um projeto de educação popular.

                          <br/><br/>Sabemos como cada vez mais o ensino superior nas universidades públicas é concorrido, e também como muitos não possuem recursos para pagarem por uma preparação nos cursinhos privados.

                      <br/><br/>Assim, ele é fruto do desejo de mudar realidades a partir do acesso ao ensino superior público e de qualidade, e, para isso, foi pensado de modo a oferecer um ensino preparatório e emancipador para seus alunos, que serão egressos do ensino público de Uberaba e região.</p>
                      </header>
                  </section>

                  <section className="box special">
                      <header className="major">


                                  <span className="icon solid fa-star major accent4"> </span><h2>O que é? </h2>
                                  <p>O cursinho é um projeto organizado pela medicina UFTM que visa dar oportunidade para alunos da rede pública terem acesso a um preparo adequado para fazerem o ENEM e conseguirem uma vaga no Ensino Superior.</p>

                          <br/><br/>

                                  <span className="icon solid fa-map-marker major accent3"></span> <h2>  Quando ele vai acontecer?</h2>
                                  <p>As aulas do cursinho que deverão acontecer no CE de segunda a sábado vão de Março até Novembro de 2023, no período das 18:00 até 22:00.</p>

                          <br/><br/>

                                  <span className="icon solid fa-users major accent5"></span> <h2>  Quem pode se inscrever como voluntário?</h2>
                                  <p>Todo e qualquer aluno de graduação da UFTM, professores e comunidade externa.</p>

                          <br/><br/>

                                  <span className="icon solid fa-exclamation-circle major accent3"></span> <h2>  Quem pode participar como aluno?</h2>
                                  <p>Todo e qualquer aluno que já concluiu o Ensino Médio em Escola Pública ou que está cursando o último ano do Ensino Médio.</p>

                          <br/><br/>
                                  <span className="icon solid fa-check-circle major accent4"></span> <h2>  Como fazer parte??</h2>
                                <p> É só fazer a inscrição no nosso formulário, e comparecer para a prova de conhecimentos gerais no dia 25 de fevereiro, às 9:00 no Centro Educacional da Universidade Federal do Triângulo Mineiro: Av. Getúlio Guaritá, 159, Nossa Senhora da Abadia, Uberaba. A sala da prova será informada posteriormente aos candidatos. Os resultados da prova de seleção serão divulgados no nosso site, depois é só fazer a matrícula!</p>
                      </header>
                  </section>


                  {/*<div className="row">*/}
                  {/*    <div className="col-6 col-12-narrower">*/}

                  {/*        <section className="box special">*/}
                  {/*            <span className="image featured"><img src="images/pic02.jpg" alt=""/></span>*/}
                  {/*            <h3>Sed lorem adipiscing</h3>*/}
                  {/*            <p>Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut*/}
                  {/*                enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros.</p>*/}
                  {/*            <ul className="actions special">*/}
                  {/*                <li><a href="#" className="button alt">Learn More</a></li>*/}
                  {/*            </ul>*/}
                  {/*        </section>*/}

                  {/*    </div>*/}
                  {/*    <div className="col-6 col-12-narrower">*/}

                  {/*        <section className="box special">*/}
                  {/*            <span className="image featured"><img src="images/pic03.jpg" alt=""/></span>*/}
                  {/*            <h3>Accumsan integer</h3>*/}
                  {/*            <p>Integer volutpat ante et accumsan commophasellus sed aliquam feugiat lorem aliquet ut*/}
                  {/*                enim rutrum phasellus iaculis accumsan dolore magna aliquam veroeros.</p>*/}
                  {/*            <ul className="actions special">*/}
                  {/*                <li><a href="#" className="button alt">Learn More</a></li>*/}
                  {/*            </ul>*/}
                  {/*        </section>*/}

                  {/*    </div>*/}
                  {/*</div>*/}

              </section>



              <section id="cta">

                  <h2>Em breve mais informações do Cursinho Carol!</h2>
                  <h3>Acompanhe nosso instagram </h3>
                  <h2><a href="https://www.instagram.com/cursinho.carolina.uftm/" className="icon brands fa-instagram"><span className="label"> cursinho.carolina.uftm
                      </span> cursinho.carolina.uftm</a> </h2>
                  <h3>Nosso e-mail </h3>
                  <h3>cursinhocarolinauftm@gmail.com</h3>

                  {/*<form>*/}
                  {/*    <div className="row gtr-50 gtr-uniform">*/}
                  {/*        <div className="col-8 col-12-mobilep">*/}
                  {/*            <input type="email" name="email" id="email" placeholder="Email Address"/>*/}
                  {/*        </div>*/}
                  {/*        <div className="col-4 col-12-mobilep">*/}
                  {/*            <input type="submit" value="Sign Up" className="fit"/>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</form>*/}

              </section>


              <footer id="footer">
                  {/*<ul className="icons">*/}
                  {/*    /!*<li><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>*/}
                  {/*    <li><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a>*/}
                  {/*    </li>*!/*/}
                  {/*    <li><a href="https://www.instagram.com/cursinho.carolina.uftm/" className="icon brands fa-instagram"><span className="label">Instagram: cursinho.carolina.uftm*/}
                  {/*    </span></a>*/}
                  {/*    </li>*/}
                  {/*    /!*<li><a href="#" className="icon brands fa-github"><span className="label">Github</span></a></li>*!/*/}
                  {/*    /!*<li><a href="#" className="icon brands fa-dribbble"><span className="label">Dribbble</span></a>*!/*/}
                  {/*    /!*</li>*!/*/}
                  {/*    /!*<li><a href="#" className="icon brands fa-google-plus"><span className="label">Google+</span></a>*!/*/}
                  {/*    /!*</li>*!/*/}
                  {/*</ul>*/}
                  <Logo/>
                  <ul className="copyright">
                      <li>Projeto de extensão - N.º 19/2023 - Universidade Federal do Triângulo Mineiro - UFTM</li>
                  </ul>


              </footer>
        </div>
    </div>
  );
}

export default App;
