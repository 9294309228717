import logocursinho from './html5up-alpha/images/cursinhologo.png';



// const LogoCursinho = () => {
//     return (
//         <img
//             src={logocursinho}
//             alt={alt}
//             srcset={`${logocursinho} 1x, ${logocursinho} 2x`}
//             sizes="(max-width: 480px) 100vw, (max-width: 768px) 50vw, 25vw"
//         />
//     );
// };
//
// export default LogoCursinho;


function LogoCursinho() {
    // Import result is the URL of your image
    return   <img
        srcSet={`${logocursinho} 320w, ${logocursinho} 680w, ${logocursinho}   960w, ${logocursinho} 1980w`}
        src={logocursinho} alt=""
        sizes="(max-width: 480px) 150vw, (max-width: 768px) 130vw,130vw"
    />
}

export default LogoCursinho;